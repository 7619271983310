<template>
  <!-- HORIZONTAL LAYOUT -->
  <vx-card title="Edit Product Must Have">
    <div class="vx-row mb-6" style="width: 50%">
      <vs-button
        class="ml-4 mt-2"
        color="danger"
        icon-pack="feather"
        icon="icon-arrow-left"
        @click="handleBack()"
        >Back</vs-button
      >
    </div>
    <div class="vx-row">
      <div class="vx-col sm:w-1/2 w-full mb-base">
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/6 w-full">
            <span>Code</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input class="w-full" v-model="code" disabled />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/6 w-full">
            <span>Territory</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedTerritory"
              :options="optionTerritory"
              multiple
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="false"
              placeholder="Type to search"
              :searchable="true"
              track-by="id"
              @search-change="handlerSearchChangeT"
              :custom-label="customLableTerritory"
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/6 w-full">
            <span> Distribution Channel </span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedDistributionChannel"
              :options="optionDistributionChannel"
              multiple
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="false"
              placeholder="Type to search"
              :searchable="true"
              track-by="id"
              @search-change="handlerSearchChangeCC"
              :custom-label="customLableDistributionChannel"
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/6 w-full">
            <span>Customer Group 1 </span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedCustomerGroup1"
              :options="optionCustomerGroup1"
              multiple
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="false"
              placeholder="Type to search"
              :searchable="true"
              track-by="id"
              @search-change="handlerSearchChangeCG1"
              :custom-label="customLableCustomerGroup1"
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/6 w-full">
            <span>Customer Group 2</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedCustomerGroup2"
              :options="optionCustomerGroup2"
              multiple
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="false"
              placeholder="Type to search"
              :searchable="true"
              track-by="id"
              @search-change="handlerSearchChangeCG2"
              :custom-label="customLableCustomerGroup1"
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/6 w-full">
            <span>Customer</span>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedCustomer"
              :options="optionCustomer"
              :multiple="true"
              :allow-empty="true"
              :group-select="false"
              :max-height="100"
              :limit="5"
              track-by="id"
              :internal-search="false"
              placeholder="Type to search"
              :searchable="true"
              @search-change="handlerSearchChange"
              :custom-label="customLableCustomer"
            />
          </div>
          <div class="vx-col sm:w-1/6 w-full text-right">
            <vs-button
              size="medium"
              class="mr-2 mb-1 bg-success"
              icon-pack="feather"
              icon="icon-search"
              @click="handlerSearchGetCustomer()"
              >refresh</vs-button
            >
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/6 w-full">
            <span>Salesman Type</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedTypeSalesman"
              :options="optionTypeSalesman"
              multiple
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="false"
              placeholder="Type to search"
              :searchable="true"
              track-by="id"
              @search-change="handlerSearchChangeWp1"
              :custom-label="customLableWorkPosition"
            />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/6 w-full">
            <span>Salesman Subtype</span>
          </div>
          <div class="vx-col sm:w-1/2 w-full">
            <multiselect
              class="selectExample"
              v-model="selectedWorkPosition"
              :options="optionWorkPosition"
              multiple
              :filterable="true"
              :allow-empty="true"
              :group-select="true"
              :max-height="100"
              :limit="3"
              :internal-search="false"
              placeholder="Type to search"
              :searchable="true"
              track-by="id"
              @search-change="handlerSearchChangeWp2"
              :custom-label="customLableWorkPosition"
            />
          </div>
          <div class="vx-col sm:w-1/6 w-full text-right">
            <vs-button
              size="medium"
              class="mr-2 mb-1 bg-success"
              icon-pack="feather"
              icon="icon-search"
              @click="handlerSearchGetWorkPosition()"
              >refresh</vs-button
            >
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/6 w-full">
            <span>Valid From - To</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <date-range-picker
              style="min-height: 40px"
              class="w-full"
              ref="picker"
              opens="center"
              :locale-data="{ firstDay: 1, format: 'dd Mon yyyy' }"
              :singleDatePicker="false"
              :timePicker="false"
              :timePicker24Hour="false"
              :showWeekNumbers="false"
              :showDropdowns="false"
              :autoApply="true"
              v-model="validity"
              :linkedCalendars="true"
            >
              <template v-slot:input="picker" style="min-width: 350px">
                {{ dateFormat(picker.startDate) }} -
                {{ dateFormat(picker.endDate) }}
              </template>
            </date-range-picker>
          </div>
        </div>

        <div class="vx-row mb-6" style="width: 50%">
          <div class="vx-col sm:w-1/3 w-full flex items-center">
            <span>Status</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <!-- <input type="radio" id="active" value="Active" v-model="status" />
            <label for="active">Active</label> -->
            <vs-switch width="120px" color="success" v-model="status">
              <span slot="on">Active</span>
              <span slot="off">Inactive</span>
            </vs-switch>
            <!-- <input
              type="radio"
              id="inactive"
              value="Inactive"
              v-model="status"
            />
            <label for="inactive">Inactive</label> -->
          </div>
        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/6 w-full">
            <span>Line Items</span>
          </div>
          <div class="vx-col sm:w-5/6 w-full">
            <vs-button size="small" class="mr-3 mb-2" @click="browseItem()"
              >Browse</vs-button
            >
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col sm:w-6/12 w-full" v-if="update">
            <vs-button class="mr-3 mb-2" v-on:click="SubmitForm()"
              >Save</vs-button
            >
            <!-- <vs-button v-if="quantityChecked != true" class="mr-3 mb-2" v-on:click="CheckQty()">CheckQty</vs-button> -->
            <!-- <vs-button color="danger" class="mr-3 mb-2" @click="CancelForm()"
              >Cancel</vs-button
            > -->
          </div>
        </div>
        <br />
        <hr />
        <br />
        <!-- <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-table
              :data="cartProducts"
              stripe
              style="width: 100%"
              search
              :sst="true"
              @change-page="handleChangePageItem"
              @search="handleSearchItem"
              @sort="handleSortItem"
            >
              <template slot="thead">
                <vs-th>SKU</vs-th>
                <vs-th>Name</vs-th>
                <vs-th>Category</vs-th>
                <vs-th>Unit</vs-th>
                <vs-th>Quantity</vs-th>
                <vs-th>Action</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr :key="index" v-for="(dt, index) in data">
                  <vs-td style="align-item-center">{{ dt.skuCode }}</vs-td>
                  <vs-td style="align-item-center">{{ dt.Name }}</vs-td>
                  <vs-td style="align-item-center">{{ dt.Categories }}</vs-td>
                  <vs-td style="align-item-center">
                    <vs-tr
                      v-for="(ds, idx1) in dt.itemUnitId"
                      :key="idx1"
                      style="align-item-center"
                      >{{ ds.Unit }}
                      <vs-input
                        type="number"
                        v-model.number="ds.value"
                        style="width: 100px"
                        @input="calculUnitToQty(ds, index, idx1)"
                      />
                    </vs-tr>
                  </vs-td>
                  <vs-td style="align-item-center">
                    <vs-input
                      type="number"
                      v-model.number="dt.qty"
                      :min="1"
                      style="width: 70px"
                      @input="calculateConversion(dt.qty, index)"
                    />
                  </vs-td>
                  <vs-td style="align-item-center">
                    <feather-icon
                      title="Delete"
                      icon="TrashIcon"
                      svgClasses="w-5 h-5 text-danger stroke-current"
                      class="ml-2"
                      @click="deleteItemLine(index)"
                    />
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div> -->
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-table
              :data="cartProducts"
              stripe
              style="width: 100%"
              search
              :sst="true"
              @change-page="handleChangePageItem"
              @search="handleSearchItem"
              @sort="handleSortItem"
            >
              <template slot="thead">
                <vs-th>SKU</vs-th>
                <vs-th>Name</vs-th>
                <vs-th>Category</vs-th>
                <!-- <vs-th>Unit</vs-th>
                  <vs-th>Quantity</vs-th> -->
                <vs-th>Action</vs-th>
              </template>

              <template slot-scope="{ data }">
                <vs-tr :key="index" v-for="(dt, index) in data">
                  <vs-td style="align-item-center">{{ dt.skuCode }}</vs-td>
                  <vs-td style="align-item-center">{{ dt.Name }}</vs-td>
                  <vs-td style="align-item-center">{{ dt.Categories }}</vs-td>
                  <!-- <vs-td style="align-item-center">{{ dt.Unit }}</vs-td>
                    <vs-td style="align-item-center">
                      <vs-input type="number" v-model.number="dt.qty" :min="1" />
                    </vs-td> -->
                  <vs-td style="align-item-center">
                    <feather-icon
                      title="Delete"
                      icon="TrashIcon"
                      svgClasses="w-5 h-5 text-danger stroke-current"
                      class="ml-2"
                      @click="deleteItemLine(index)"
                    />
                  </vs-td>
                </vs-tr>
              </template>
            </vs-table>
          </div>
        </div>
      </div>
      <div
        v-if="this.optionItemAvailableShow"
        class="vx-col sm:w-1/2 w-full mb-base"
      >
        <div class="vx-row mb-6">
          <h3>Item Unit</h3>
        </div>
        <vs-table
          search
          stripe
          border
          description
          :sst="true"
          :data="items"
          :max-items="length"
          :total="total"
          @search="handleSearch"
          @change-page="handleChangePage"
          @sort="handleSort"
        >
          <template slot="header">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2"
                  >{{ this.start }} - {{ this.end }} of {{ this.total }}</span
                >
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in limits"
                  :key="item"
                  @click="handleChangelength(item)"
                >
                  <span>{{ item }}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </template>
          <template slot="thead">
            <vs-th sort-key="sku">SKU</vs-th>
            <vs-th sort-key="name">Name</vs-th>
            <vs-th sort-key="name">Supplier</vs-th>
            <vs-th sort-key="name">Category</vs-th>
            <!-- <vs-th sort-key="name">Unit</vs-th> -->
            <vs-th sort-key="action">Action</vs-th>
          </template>

          <template slot-scope="{ data }">
            <vs-tr
              :data="tr"
              :key="indextr"
              v-for="(tr, indextr) in data.records"
            >
              <vs-td :data="data.records[indextr].sku_code">{{
                data.records[indextr].sku_code
              }}</vs-td>
              <vs-td :data="data.records[indextr].item_name"
                >{{ data.records[indextr].item_name }}
              </vs-td>
              <vs-td :data="data.records[indextr].supplier_name"
                >{{ data.records[indextr].supplier_name }}
              </vs-td>
              <vs-td :data="data.records[indextr].categories_name"
                >{{ data.records[indextr].categories_name }}
              </vs-td>
              <!-- <vs-td :data="data.records[indextr].unit_name"
                >{{ data.records[indextr].unit_name }}
              </vs-td> -->
              <vs-td style="align-item-center">
                <feather-icon
                  title="Add"
                  icon="PlusIcon"
                  svgClasses="w-5 h-5 text-success stroke-current"
                  class="ml-2"
                  @click="addItemLine(tr)"
                />
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination
          style="padding-top: 5px"
          :total="totalPage"
          v-model="setPage"
        />
      </div>
    </div>
  </vx-card>
</template>

<script>
import ArrowUpDownBold from "vue-material-design-icons/ArrowUpDownBold.vue";
import PlusBox from "vue-material-design-icons/PlusBox.vue";

import draggable from "vuedraggable";

import Datepicker from "vuejs-datepicker";
import vSelect from "vue-select";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
export default {
  components: {
    draggable,
    ArrowUpDownBold,
    PlusBox,
    Datepicker,
    vSelect,
    DateRangePicker,
  },
  async mounted() {
    this.update = true;
    await this.getTerritory();
    await this.getCustomer();
    await this.getWorkPosition();
    await this.getTypeSalesman();
    await this.getCustomerCategory();
    await this.getCustomerGroup1();
    await this.getCustomerGroup2();
    await this.getProductItems();
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
  watch: {
    search: function () {
      if (this.optionItemAvailableShow) {
        this.getOptionItemAvailable();
      }
    },
    itemsQuantity: function () {
      this.quantityChecked = false;
    },
  },
  data: () => ({
    update: false,
    quantityChecked: false,
    external: false,

    cartProducts: [],
    itemUnitID: [],

    optionTerritory: [],
    selectedTerritory: [],
    optionWorkPosition: [],
    selectedWorkPosition: [],
    optionTypeSalesman: [],
    selectedTypeSalesman: [],
    optionCustomer: [],
    selectedCustomer: [],
    optionSalesChannel: [],
    selectedSalesChannel: [],
    optionDistributionChannel: [],
    selectedDistributionChannel: [],
    optionCustomerGroup1: [],
    selectedCustomerGroup1: [],
    optionCustomerGroup2: [],
    selectedCustomerGroup2: [],

    optionItemAvailable: [{}],

    optionCustomerShow: false,
    optionItemAvailableShow: false,

    limits: [10, 25, 50, 100, "All"],
    limitShow: 10,
    drawData: 0,
    isActive: 1,
    recordsTotal: 0,
    maxPage: 2,
    dataEnd: 0,

    items: [],
    start: 1,
    end: 0,
    length: 10,
    page: 1,
    search: "",
    itemSearch: "",
    order: "id",
    sort: "desc",
    total: 0,
    totalPage: 0,
    totalSearch: 0,
    name: "",
    code: "",
    Companyid: 0,
    ProductItem: [],
    id: 0,
    flag: 0,
    status: true,
    validity: {
      startDate: null,
      endDate: null,
    },
    minValidFrom: null,
    maxValidTo: null,
    pcs: 0,
    lusin: 0,
    karton: 0,
    inputValue: "",
    optionalItemUnit: [],
    selectedItemUnit: [],
    cartProductChild: [],
  }),
  methods: {
    calculUnitToQty(item, index, idx1) {
      console.log("KKKK", this.cartProducts[index].itemUnitId.length);
      let total = 0;

      this.cartProducts[index].itemUnitId.forEach((element) => {
        total += element.AmountUOM * element.value;
      });
      this.cartProducts[index].qty = total;
    },
    calculateConversion(item, index) {
      console.log("IIIIII", index);

      var arrayItem = [];
      arrayItem = this.cartProducts[index];
      arrayItem.itemUnitId.sort((a, b) => b.level - a.level);

      this.cartProducts[index].itemUnitId.forEach((ds) => {
        var amount_uom = ds.AmountUOM;

        const karton = Math.floor(item / amount_uom);
        item %= amount_uom;
        ds.value = karton;
      });
    },
    handleBack() {
      this.$router.push({
        name: "product-must-haves",
      });
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    CancelForm() {
      this.update = true;
      this.quantityChecked = false;
      this.external = false;

      this.itemsCode = [];
      this.itemsName = [];
      this.product_teams_id = [];
      this.itemid = [];

      this.cartProducts = [];

      this.itemsUom = [];
      this.itemsQuantity = [];

      this.optionItemAvailable = [{}];

      this.optionItemAvailableShow = false;

      this.$emit("selectReservation", {});
    },
    browseItem() {
      this.search = "";
      this.optionItemAvailableShow = true;
      this.optionCustomerShow = false;
      this.getOptionItemAvailable();
      this.scrollToTop();
    },
    // addItemLine(item) {
    //   // console.log("ini itemLine>>>",);
    //   this.quantityChecked = false;
    //   let check = this.itemUnitID.filter((val) => {
    //     return item.id == val;
    //   });
    //   console.log(check);
    //   this.$http
    //     .get("/api/sfa/v1/product-must-haves/item-unit", {
    //       params: {
    //         search: this.searchwp1,
    //         itemId: item.item_id,
    //       },
    //     })
    //     .then((resp) => {
    //       if (resp.code == 200) {
    //         if (resp.data.records) {
    //           this.optionalItemUnit = [];
    //           this.cartProductChild = [];
    //           for (let index = 0; index < resp.data.records.length; index++) {
    //             this.cartProductChild.push({
    //               itemUnitId: resp.data.records[index].id,
    //               level: resp.data.records[index].level,
    //               itemId: resp.data.records[index].item_id,
    //               skuCode: resp.data.records[index].item.sku_code,
    //               Name: resp.data.records[index].item.name,
    //               Unit: resp.data.records[index].unit.Name,
    //               AmountUOM: resp.data.records[index].amount_uom,
    //               value: 0,
    //               total: 0,
    //             });
    //           }
    //           if (check.length == 0) {
    //             this.cartProducts.push({
    //               itemUnitId: this.cartProductChild,
    //               itemId: item.item_id,
    //               skuCode: item.sku_code,
    //               Categories: item.categories_name,
    //               Name: item.item_name,
    //               Unit: item.unit_name,
    //               qty: 1,
    //             });
    //             this.itemUnitID.push(item.id);
    //             console.log("MMMM", this.cartProducts);
    //           } else {
    //             this.$vs.notify({
    //               title: "Warning",
    //               text: "You're not allowed to add or remove external Reservation item line ",
    //               color: "warning",
    //               position: "top-right",
    //               iconPack: "feather",
    //               icon: "icon-check",
    //             });
    //           }
    //         } else {
    //           this.optionalItemUnit = [];
    //           this.selectedItemUnit = {};
    //         }
    //         this.$vs.loading.close();
    //       } else {
    //         this.$vs.loading.close();
    //       }
    //     });
    // },
    addItemLine(item) {
      this.quantityChecked = false;
      let check = this.itemUnitID.filter((val) => {
        return item.item_id == val;
      });
      console.log(check);

      if (check.length == 0) {
        this.cartProducts.push({
          itemUnitId: item.id,
          itemId: item.item_id,
          skuCode: item.sku_code,
          Categories: item.categories_name,
          Name: item.item_name,
          // Unit: item.unit_name,
        });
        this.itemUnitID.push(item.item_id);
      } else {
        this.$vs.notify({
          title: "Warning",
          text: "You're not allowed to add or remove external Reservation item line ",
          color: "warning",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    deleteItemLine(index) {
      this.quantityChecked = false;
      if (this.external == false) {
        // this.itemsCode.splice(index, 1);
        // this.itemsName.splice(index, 1);
        // this.itemid.splice(index, 1);
        // this.itemCategory.splice(index, 1);
        // this.itemUnit.splice(index, 1);
        this.cartProducts.splice(index, 1);
        this.itemUnitID.splice(index, 1);
      } else {
        this.$vs.notify({
          title: "Warning",
          text: "You're not allowed to add or remove external Reservation item line",
          color: "warning",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    handleChangelength(val) {
      this.length = val == "All" ? this.total : val;
      this.page = 1;
      this.getOptionItemAvailable();
    },
    getOptionItemAvailable(page = 1) {
      this.isActive = page;
      // this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/product-must-haves/table", {
          params: {
            territory_id: this.territory_id,
            length: this.length,
            page: this.page,
            search: this.search,
            order: this.order,
            sort: this.sort,
          },
        })
        .then((resp) => {
          console.log(">>>>>>>>", resp);
          if (resp.code == 200) {
            let company_id = this.$store.state.user.currentUser.company_id;

            this.optionItemAvailable = resp.data.ItemsAvailable;
            this.total = resp.data.total_record;
            this.totalPage = resp.data.total_page;
            this.totalSearch = resp.data.total_record_search;
            this.length = resp.data.total_record_per_page;
            this.items = resp.data;
            this.Companyid = company_id;
            // this.$vs.loading.close();
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Item Available",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          // this.$vs.loading.close();
        });
    },
    SubmitForm() {
      console.log(">>>>", this.selectedCustomer);
      if (this.cartProducts.length == 0) {
        this.$vs.notify({
          title: "Warning",
          text: "You not select Item Line ",
          color: "warning",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        this.handleBack();
        return true;
      }
      if (!this.validity.startDate || !this.validity.endDate) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Valid from and valid to is required",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return false;
      }
      let territoryids = [];
      // territoryids.push(this.selectedTerritory.id);
      this.selectedTerritory.forEach((element) => {
        territoryids.push(element.id);
      });
      let typeSalesmanids = [];
      // typeSalesmanids.push(this.selectedTypeSalesman.id);
      this.selectedTypeSalesman.forEach((element) => {
        typeSalesmanids.push(element.id);
      });
      let workPositionids = [];
      // workPositionids.push(this.selectedWorkPosition.id);
      this.selectedWorkPosition.forEach((element) => {
        workPositionids.push(element.id);
      });
      let customerCategoryids = [];
      // customerCategoryids.push(this.selectedDistributionChannel.id);
      this.selectedDistributionChannel.forEach((element) => {
        customerCategoryids.push(element.id);
      });
      // let salesChannelids = [];
      // salesChannelids.push(this.selectedSalesChannel.id);
      // // this.selectedSalesChannel.forEach((element) => {

      // // });
      let CustomerGroupids1 = [];
      // CustomerGroupids1.push(this.selectedCustomerGroup1.id);
      this.selectedCustomerGroup1.forEach((element) => {
        CustomerGroupids1.push(element.id);
      });
      let CustomerGroupids2 = [];
      // CustomerGroupids2.push(this.selectedCustomerGroup2.id);
      this.selectedCustomerGroup2.forEach((element) => {
        CustomerGroupids2.push(element.id);
      });
      let Customerids = [];
      if (this.selectedCustomer.id == 0) {
        Customerids.push((this.selectedCustomer.id = 0));
      } else {
        this.selectedCustomer.forEach((element) => {
          Customerids.push(element.id);
        });
      }

      this.$vs.loading();
      this.$http
        .put("/api/sfa/v1/product-must-haves/update", {
          id: this.$route.query.id,
          territory_id: territoryids ? territoryids : 0,
          work_position_id: workPositionids ? workPositionids : 0,
          type_salesman_id: typeSalesmanids ? typeSalesmanids : 0,
          customer_category_id: customerCategoryids ? customerCategoryids : 0,
          // sales_channel_id: salesChannelids,
          customer_group_id_1: CustomerGroupids1 ? CustomerGroupids1 : 0,
          customer_group_id_2: CustomerGroupids2 ? CustomerGroupids2 : 0,
          customer_id: Customerids,
          company_id: this.company_id,
          item_id: this.cartProducts,
          status: this.status
            ? this.status == true
              ? "Active"
              : "Inactive"
            : "Inactive",
          valid_from: this.validity.startDate
            ? moment(this.validity.startDate).format("YYYY-MM-DD")
            : null,
          valid_to: this.validity.endDate
            ? moment(this.validity.endDate).format("YYYY-MM-DD")
            : null,
        })
        .then((resp) => {
          console.log(resp);
          if (resp.code == 200) {
            this.$vs.notify({
              title: "Success",
              text: "Reservation has been updated",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.handleBack();
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    setStartEnd() {
      let valStart = this.length * this.page - this.length + 1;
      if (valStart > this.total) {
        valStart = 1;
      }
      if (this.total == 0) {
        valStart = 0;
      }
      let valEnd = this.length * this.page;
      if (valEnd > this.total) {
        valEnd = this.total;
      }
      if (this.totalSearch < this.total) {
        valEnd = this.totalSearch;
      }

      this.start = valStart;
      this.end = valEnd;
    },
    handleSearch(searching) {
      this.search = searching;
      this.page = 1;
      this.getOptionItemAvailable(this.isActive);
    },
    handleChangePage(page) {
      this.page = page;
      this.getOptionItemAvailable(this.isActive);
    },
    handleSort(key, active) {
      this.order = key;
      this.sort = active;
      this.getOptionItemAvailable(this.isActive);
    },
    getProductItems() {
      this.$http
        .get("/api/sfa/v1/product-must-haves/edit", {
          params: {
            company_id: this.$route.query.company_id,
            id: this.$route.query.id,
            page: this.page,
            search: this.itemSearch,
            order: this.order,
            sort: this.sort,
            length: this.length,
          },
        })
        .then((resp) => {
          console.log("mmmmmmm", resp);
          if (resp.code == 200) {
            this.items = resp.data.records;
            this.itemsCode = [];
            this.itemsName = [];
            this.itemsUom = [];
            this.itemUnitid = [];
            this.status = resp.data.records.Status
              ? resp.data.records.Status == "Active"
                ? true
                : false
              : false;
            this.code = resp.data.records.Code;
            this.validity.startDate = moment(
              resp.data.records.ValidFrom
            ).format("YYYY-MM-DD");
            this.validity.endDate = moment(resp.data.records.ValidTo).format(
              "YYYY-MM-DD"
            );

            for (let index = 0; index < resp.data.Data_Item.length; index++) {
              this.cartProducts.push({
                // itemUnitID: resp.data.Data_Item[index].ItemID,
                itemUnitId: resp.data.Data_Item[index].ItemID,
                itemId: resp.data.Data_Item[index].ItemID,
                skuCode: resp.data.Data_Item[index].Item.sku_code,
                Name: resp.data.Data_Item[index].Item.name_item,
                // Unit: resp.data.Data_Item[index].Unit.name_unit,
                Categories: resp.data.Data_Item[index].Category.categories_name,
                // qty: resp.data.MusthaveLine[index].Qty,
              });
              this.itemUnitID.push(resp.data.Data_Item[index].ItemID);
            }
            console.log(this.itemUnitID);

            // this.selectTerritory = {
            //   id: resp.data.records.Territoryid,
            // };
            this.selectedTerritory = resp.data.territory
              ? resp.data.territory.length > 0
                ? resp.data.territory
                : [{ code: "", name: "ALL", id: 0 }]
              : [{ code: "", name: "ALL", id: 0 }];
            this.selectedTypeSalesman = resp.data.typeSalesman
              ? resp.data.typeSalesman.length > 0
                ? resp.data.typeSalesman
                : [{ Name: "ALL", id: 0 }]
              : [{ Name: "ALL", id: 0 }];

            this.selectedWorkPosition = resp.data.workPosition
              ? resp.data.workPosition.length > 0
                ? resp.data.workPosition
                : [{ Name: "ALL", id: 0 }]
              : [{ Name: "ALL", id: 0 }];

            this.selectedCustomerGroup1 = resp.data.customerGroup1
              ? resp.data.customerGroup1.length > 0
                ? resp.data.customerGroup1
                : [{ code: "", name: "ALL", id: 0 }]
              : [{ code: "", name: "ALL", id: 0 }];

            this.selectedCustomerGroup2 = resp.data.customerGroup2
              ? resp.data.customerGroup2.length > 0
                ? resp.data.customerGroup2
                : [{ code: "", name: "ALL", id: 0 }]
              : [{ code: "", name: "ALL", id: 0 }];

            this.selectedDistributionChannel = resp.data.customerCategory
              ? resp.data.customerCategory.length > 0
                ? resp.data.customerCategory
                : [
                    {
                      name: "ALL",
                      classification: "",
                      id: 0,
                    },
                  ]
              : [
                  {
                    name: "ALL",
                    classification: "",
                    id: 0,
                  },
                ];
            this.selectedCustomer = resp.data.customer
              ? resp.data.customer.length > 0
                ? resp.data.customer
                : { code: "", name: "ALL", id: 0 }
              : { code: "", name: "ALL", id: 0 };
          }
        });
    },
    // getProductItems() {
    //   this.$http
    //     .get("/api/sfa/v1/product-must-haves/edit", {
    //       params: {
    //         company_id: this.$route.query.company_id,
    //         id: this.$route.query.id,
    //         page: this.page,
    //         search: this.itemSearch,
    //         order: this.order,
    //         sort: this.sort,
    //         length: this.length,
    //       },
    //     })
    //     .then((resp) => {
    //       console.log("mmmmmmm", resp);
    //       if (resp.code == 200) {
    //         this.items = resp.data.records;
    //         this.itemsCode = [];
    //         this.itemsName = [];
    //         this.itemsUom = [];
    //         this.itemUnitid = [];
    //         this.status = resp.data.records.Status
    //           ? resp.data.records.Status == "Active"
    //             ? true
    //             : false
    //           : false;
    //         this.code = resp.data.records.Code;
    //         this.validity.startDate = moment(
    //           resp.data.records.ValidFrom
    //         ).format("YYYY-MM-DD");
    //         this.validity.endDate = moment(resp.data.records.ValidTo).format(
    //           "YYYY-MM-DD"
    //         );
    //         let currentItemID = null;
    //         let currentItemUnits = [];

    //         for (let index = 0; index < resp.data.Data_Item.length; index++) {
    //           const currentItem = resp.data.Data_Item[index];

    //           if (currentItem.ItemID !== currentItemID) {
    //             if (currentItemID !== null) {
    //               this.cartProducts.push({
    //                 itemId: currentItemID,
    //                 skuCode: currentItemUnits[0].Item.sku_code,
    //                 Name: currentItemUnits[0].Item.name_item,
    //                 qty: currentItemUnits[0].Qty,
    //                 Categories: currentItemUnits[0].Category.categories_name,
    //                 itemUnitId: currentItemUnits.map((unit) => ({
    //                   itemUnitID: unit.ItemUnit.item_unit_id,
    //                   itemUnitId: unit.ItemUnit.item_unit_id,
    //                   Unit: unit.Unit.name_unit,
    //                   value: unit.ItemUnit.qty,
    //                   level: unit.ItemUnit.level,
    //                   AmountUOM: unit.ItemUnit.amount_uom,
    //                 })),
    //               });
    //             }

    //             currentItemID = currentItem.ItemID;
    //             currentItemUnits = [];
    //           }
    //           currentItemUnits.push(currentItem);
    //         }

    //         if (currentItemID !== null) {
    //           this.cartProducts.push({
    //             itemId: currentItemID,
    //             skuCode: currentItemUnits[0].Item.sku_code,
    //             Name: currentItemUnits[0].Item.name_item,
    //             qty: currentItemUnits[0].Qty,
    //             Categories: currentItemUnits[0].Category.categories_name,
    //             itemUnitId: currentItemUnits.map((unit) => ({
    //               itemUnitID: unit.ItemUnit.item_unit_id,
    //               itemUnitId: unit.ItemUnit.item_unit_id,
    //               Unit: unit.Unit.name_unit,
    //               value: unit.ItemUnit.qty,
    //               level: unit.ItemUnit.level,
    //               AmountUOM: unit.ItemUnit.amount_uom,
    //             })),
    //           });
    //         }

    //         this.selectedTerritory = resp.data.territory
    //           ? resp.data.territory.length > 0
    //             ? resp.data.territory
    //             : [{ code: "", name: "ALL", id: 0 }]
    //           : [{ code: "", name: "ALL", id: 0 }];
    //         this.selectedTypeSalesman = resp.data.typeSalesman
    //           ? resp.data.typeSalesman.length > 0
    //             ? resp.data.typeSalesman
    //             : [{ Name: "ALL", id: 0 }]
    //           : [{ Name: "ALL", id: 0 }];

    //         this.selectedWorkPosition = resp.data.workPosition
    //           ? resp.data.workPosition.length > 0
    //             ? resp.data.workPosition
    //             : [{ Name: "ALL", id: 0 }]
    //           : [{ Name: "ALL", id: 0 }];

    //         this.selectedCustomerGroup1 = resp.data.customerGroup1
    //           ? resp.data.customerGroup1.length > 0
    //             ? resp.data.customerGroup1
    //             : [{ code: "", name: "ALL", id: 0 }]
    //           : [{ code: "", name: "ALL", id: 0 }];

    //         this.selectedCustomerGroup2 = resp.data.customerGroup2
    //           ? resp.data.customerGroup2.length > 0
    //             ? resp.data.customerGroup2
    //             : [{ code: "", name: "ALL", id: 0 }]
    //           : [{ code: "", name: "ALL", id: 0 }];

    //         this.selectedDistributionChannel = resp.data.customerCategory
    //           ? resp.data.customerCategory.length > 0
    //             ? resp.data.customerCategory
    //             : [
    //                 {
    //                   name: "ALL",
    //                   classification: "",
    //                   id: 0,
    //                 },
    //               ]
    //           : [
    //               {
    //                 name: "ALL",
    //                 classification: "",
    //                 id: 0,
    //               },
    //             ];
    //         this.selectedCustomer = resp.data.customer
    //           ? resp.data.customer.length > 0
    //             ? resp.data.customer
    //             : { code: "", name: "ALL", id: 0 }
    //           : { code: "", name: "ALL", id: 0 };
    //       }
    //     });
    // },
    handleChangePageItem(page) {
      this.table.page = page;
      this.getProductItems(this.$route.params.id);
    },
    handleSearchItem(searching) {
      this.itemSearch = searching;
      this.page = 0;
      this.optionItemAvailableShow = false;
      this.optionCustomerShow = true;
      this.getProductItems(this.$route.params.id);
    },
    handleSortItem(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getProductItems(this.$route.params.id);
    },
    getCustomer() {
      this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/customer", {
          params: {
            length: 40,
            // page: this.table.page,
            search: this.searchCust,
            // order: this.table.order,
            // sort: this.table.sort,
            //territory_id: SelectTerritory.id,
            customer_category_id: this.customerC,
            customer_group_id_1: this.customerG1,
            customer_group_id_2: this.customerG2,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionCustomer = [{ code: "", name: "ALL", id: 0 }];
              for (let index = 0; index < resp.data.records.length; index++) {
                this.optionCustomer.push(resp.data.records[index]);
              }
              // this.optionCustomer = resp.data.records;
              if (this.optionCustomer.length > 0) {
                // this.selectedCustomer = this.optionCustomer[0];
              } else {
                this.optionCustomer = [];
                this.selectedCustomer = [];
              }
            } else {
              this.optionCustomer = [];
              this.selectedCustomer = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getCustomerGroup2() {
      this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/product-must-haves/customer-group", {
          params: {
            length: 9999,
            order: "name",
            sort: "asc",
            level: 2,
            search: this.searchCG2,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionCustomerGroup2 = [{ code: "", name: "ALL", id: 0 }];
              for (let index = 0; index < resp.data.records.length; index++) {
                this.optionCustomerGroup2.push(resp.data.records[index]);
              }
              // this.optionCustomerGroup2 = resp.data.records;
              if (this.optionCustomerGroup2.length > 0) {
                // this.selectedCustomerGroup2 = this.optionCustomerGroup2[0];
              } else {
                this.optionCustomerGroup2 = [];
                this.selectedCustomerGroup2 = [];
              }
            } else {
              this.optionCustomerGroup2 = [];
              this.selectedCustomerGroup2 = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getCustomerGroup1() {
      this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/product-must-haves/customer-group", {
          params: {
            length: 9999,
            order: "name",
            sort: "asc",
            level: 1,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionCustomerGroup1 = [{ code: "", name: "ALL", id: 0 }];
              for (let index = 0; index < resp.data.records.length; index++) {
                this.optionCustomerGroup1.push(resp.data.records[index]);
              }
              // this.optionCustomerGroup1 = resp.data.records;
              if (this.optionCustomerGroup1.length > 0) {
                // this.selectedCustomerGroup1 = this.optionCustomerGroup1[0];
              } else {
                this.optionCustomerGroup1 = [];
                this.selectedCustomerGroup1 = [];
              }
            } else {
              this.optionCustomerGroup1 = [];
              this.selectedCustomerGroup1 = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getTypeSalesman() {
      this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/product-must-haves/type-salesman", {
          params: {
            length: 9999,
            order: "name",
            sort: "asc",
            search: this.searchwp1,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionTypeSalesman = [{ Name: "ALL", id: 0 }];
              for (let index = 0; index < resp.data.records.length; index++) {
                this.optionTypeSalesman.push(resp.data.records[index]);
              }
              // this.optionTypeSalesman = resp.data.records;
              if (this.optionTypeSalesman.length > 0) {
                // this.selectedTypeSalesman = this.optionTypeSalesman[0];
              } else {
                this.optionTypeSalesman = [];
                this.selectedTypeSalesman = [];
              }
            } else {
              this.optionTypeSalesman = [];
              this.selectedTypeSalesman = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getWorkPosition() {
      this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/product-must-haves/work-position", {
          params: {
            length: 9999,
            order: "name",
            sort: "asc",
            search: this.searchwp2,
            sales_type: this.salesType,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionWorkPosition = [{ Name: "ALL", id: 0 }];
              for (let index = 0; index < resp.data.records.length; index++) {
                this.optionWorkPosition.push(resp.data.records[index]);
              }
              // this.optionWorkPosition = resp.data.records;
              if (this.optionWorkPosition.length > 0) {
                // this.selectedWorkPosition = this.optionWorkPosition[0];
              } else {
                this.optionWorkPosition = [];
                this.selectedWorkPosition = [];
              }
            } else {
              this.optionWorkPosition = [];
              this.selectedWorkPosition = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getCustomerCategory() {
      this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/product-must-haves/customer-category", {
          params: {
            length: 0,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionDistributionChannel = [
                { name: "ALL", classification: "", id: 0 },
              ];
              for (let index = 0; index < resp.data.records.length; index++) {
                this.optionDistributionChannel.push(resp.data.records[index]);
              }
              // this.optionDistributionChannel = resp.data.records;
              if (this.optionDistributionChannel.length > 0) {
                // this.selectedDistributionChannel =
                //   this.optionDistributionChannel[0];
              } else {
                this.optionDistributionChannel = [];
                this.selectedDistributionChannel = [];
              }
            } else {
              this.optionDistributionChannel = [];
              this.selectedDistributionChannel = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    getTerritory() {
      this.$vs.loading();
      this.$http
        .get("/api/sfa/v1/route-plan/territory", {
          params: {
            // length: 0,
            // order: "name",
            // sort: "asc",
            search: this.searchT,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            if (resp.data.records) {
              this.optionTerritory = [{ code: "", name: "ALL", id: 0 }];
              for (let index = 0; index < resp.data.records.length; index++) {
                this.optionTerritory.push(resp.data.records[index]);
              }
              if (this.optionTerritory.length > 0) {
                this.selectedTerritory = this.optionTerritory[0];
              } else {
                this.optionTerritory = [];
                this.selectedTerritory = [];
              }
            } else {
              this.optionTerritory = [];
              this.selectedTerritory = {};
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    customLableTerritory({ code, name }) {
      return `${code} ${name}`;
    },
    customLableSalesChannel({ code, name }) {
      return `${code} ${name}`;
    },
    customLableDistributionChannel({ name, classification }) {
      return `${name} ${classification}`;
    },
    customLableCustomer({ code, name }) {
      return `${code} ${name}`;
    },
    customLableCustomerGroup1({ code, name }) {
      return `${code} ${name}`;
    },
    customLableWorkPosition({ Name }) {
      return `${Name}`;
    },
    handlerSearchChange(search) {
      this.searchCust = search;
      this.getCustomer();
    },
    handlerSearchGetCustomer() {
      this.optionCustomer = [];
      let CustomerCat = [];
      this.selectedDistributionChannel.forEach((element) => {
        if (element.id != 0) {
          CustomerCat.push(element.id);
        }
      });
      let CustomerGR1 = [];
      this.selectedCustomerGroup1.forEach((element) => {
        if (element.id != 0) {
          CustomerGR1.push(element.id);
        }
      });
      let CustomerGR2 = [];
      this.selectedCustomerGroup2.forEach((element) => {
        if (element.id != 0) {
          CustomerGR2.push(element.id);
        }
      });
      this.customerC = CustomerCat;
      this.customerG1 = CustomerGR1;
      this.customerG2 = CustomerGR2;
      this.getCustomer();
    },
    handlerSearchChangeWp1(searching) {
      this.optionTypeSalesman = [];
      this.searchwp1 = searching;
      this.getTypeSalesman();
    },
    handlerSearchChangeWp2(searching) {
      this.optionWorkPosition = [];
      this.searchwp2 = searching;
      this.getWorkPosition();
    },
    handlerSearchChangeCC(searching) {
      this.optionDistributionChannel = [];
      this.searchCc = searching;
      this.getCustomerCategory();
    },
    handlerSearchChangeCG2(searching) {
      this.optionCustomerGroup2 = [];
      this.searchCG2 = searching;
      this.getCustomerGroup2();
    },
    handlerSearchChangeCG1(searching) {
      this.optionCustomerGroup1 = [];
      this.searchCG1 = searching;
      this.getCustomerGroup1();
    },
    handlerSearchChangeT(searching) {
      this.optionTerritory = [];
      this.searchT = searching;
      this.getTerritory();
    },
    handlerSearchGetWorkPosition() {
      this.optionWorkPosition = [];

      let TypeSales = [];
      this.selectedTypeSalesman.forEach((element) => {
        if (element.id != 0) {
          TypeSales.push(element.id);
        }
      });
      this.salesType = TypeSales;
      this.getWorkPosition();
    },
    dateFormat(date) {
      let a = null;
      if (date != null) {
        a = moment(date).format("DD MMM YYYY");
      }
      return a;
    },
  },
};
</script>
